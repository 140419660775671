import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-manage-member',
  templateUrl: './manage-member.component.html',
  styleUrls: ['./manage-member.component.scss']
})
export class ManageMemberComponent implements OnInit {

  constructor(private dataService: DataService, private router:Router, private route: ActivatedRoute) { }

  id:any = 0;
  loading:any = false;
  member:any = {status:0, data:{}, age:0};

  ngOnInit(): void {
    this.id = (this.route.snapshot.paramMap.get('id') != null) ? this.route.snapshot.paramMap.get('id') : 0;
    this.loadMember();
  }

  loadMember(){
    if(this.id != 0){
      this.dataService.getMember(this.id).subscribe( (data: any )=> {
        this.member = data;
      } );
    }
    
  }

  submitMember(data){
    data.member_id = this.id;
    this.loading = true;
    this.dataService.postUpdateMember(data).subscribe( (res:any) => {
      this.loading = false;
      
      if(res.status == 0) {
        
        //@ts-ignore
        //document.getElementById("formNew").reset();

        this.router.navigate(["/dashboard/members/"+res.id]);


      } else {
        alert(res.msg);
      }

    });
  }

}
