<div class="page">
    <div class="container-fluid">
        <div class="row page-header">
            <div class="col-md-12">
                <h3>
                    <ng-container *ngIf="id == 0">Add </ng-container>
                    <ng-container *ngIf="id != 0">Edit </ng-container>
                    Member
                </h3>
            </div>
        </div>

        <div class="page-body">
            <form #fnew="ngForm" (ngSubmit)="submitMember(fnew.value)" id="formNew" ngNativeValidate>
            
              
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-12">Name</label>
                            <div class="col-md-12">
                                <input type="text" id="inputName" [(ngModel)]="member.data.name" name="name" class="form-control" required/>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-12">Date of Birth</label>
                            <div class="col-md-12">
                                <input type="date" [(ngModel)]="member.data.dob" name="dob" class="form-control" />
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-12">Gender</label>
                            <div class="col-md-12">
                                <select class="form-control" [(ngModel)]="member.data.gender" name="gender" required>
                                    <option>Male</option>
                                    <option>Female</option>
                                    
                                </select>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-12">Staff ID</label>
                            <div class="col-md-12">
                                <input type="text" [(ngModel)]="member.data.staff_id" name="staff_id" class="form-control" />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-12">Passport No.</label>
                            <div class="col-md-12">
                                <input type="text" [(ngModel)]="member.data.passport_no" name="passport_no" class="form-control"/>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-12">Type</label>
                            <div class="col-md-12">
                                <select class="form-control" [(ngModel)]="member.data.renewal" name="renewal">
                                    <option value="1">Renewal</option>
                                    <option value="2">Non-Renewal</option>
                                    <option value="3">New</option>
                                    <!--<option value="0">Not specified</option>-->
                                </select>
                                
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-12">Policy Date</label>
                            <div class="col-md-12">
                                <input type="date" [(ngModel)]="member.data.policy_date" name="policy_date" class="form-control" />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-12">Policy Inception Date</label>
                            <div class="col-md-12">
                                <input type="date" [(ngModel)]="member.data.policy_inception" name="policy_inception" class="form-control"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-12">Policy Expiry Date</label>
                            <div class="col-md-12">
                                <input type="date" [(ngModel)]="member.data.policy_expiry" name="policy_expiry" class="form-control" />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-12">Phone</label>
                            <div class="col-md-12">
                                <input type="text" [(ngModel)]="member.data.phone" name="phone" class="form-control" />
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-12">Card No.</label>
                            <div class="col-md-12">
                                <input type="text" [(ngModel)]="member.data.card_number" name="card_number" class="form-control" />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-12">Member ID</label>
                            <div class="col-md-12">
                                <input type="text" [(ngModel)]="member.data.member_id" name="member_id" class="form-control" />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-12">Principal ID</label>
                            <div class="col-md-12">
                                <input type="text" [(ngModel)]="member.data.principal_id" name="principal_id" class="form-control" />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-12">Principal Name</label>
                            <div class="col-md-12">
                                <input type="text" [(ngModel)]="member.data.principal_name" name="principal_name" class="form-control" />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-12">Dependency</label>
                            <div class="col-md-12">
                                <input type="text" [(ngModel)]="member.data.dependency" name="dependency" class="form-control" />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-12">Category</label>
                            <div class="col-md-12">
                                <input type="text" [(ngModel)]="member.data.category" name="category" class="form-control" />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-12">Cardex No.</label>
                            <div class="col-md-12">
                                <input type="text" [(ngModel)]="member.data.cardex_no" name="cardex_no" class="form-control" />
                            </div>
                        </div>
                    </div>

                    
                    
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-12">Network</label>
                            <div class="col-md-12">
                                <input type="text" [(ngModel)]="member.data.network" name="network" class="form-control" />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-12">Inception Date</label>
                            <div class="col-md-12">
                                <input type="date" [(ngModel)]="member.data.inception_date" name="inception_date" class="form-control" />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-12">Expiry Date</label>
                            <div class="col-md-12">
                                <input type="date" [(ngModel)]="member.data.expiry_date" name="expiry_date" class="form-control" />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-12">Endo Date</label>
                            <div class="col-md-12">
                                <input type="date" [(ngModel)]="member.data.endo_date" name="endo_date" class="form-control" />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-12">Emirates ID</label>
                            <div class="col-md-12">
                                <input type="text" [(ngModel)]="member.data.emirates_id" name="emirates_id" class="form-control" />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-12">Nationality</label>
                            <div class="col-md-12">
                                <input type="text" [(ngModel)]="member.data.nationality" name="nationality" class="form-control" />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-12">Marital Status</label>
                            <div class="col-md-12">
                                <input type="text" [(ngModel)]="member.data.marital_status" name="marital_status" class="form-control" />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-12">Visa Emirate</label>
                            <div class="col-md-12">
                                <input type="text" [(ngModel)]="member.data.visa_emirate" name="visa_emirate" class="form-control" />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-12">Email</label>
                            <div class="col-md-12">
                                <input type="text" [(ngModel)]="member.data.email" name="email" class="form-control" />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-12">Resident Location</label>
                            <div class="col-md-12">
                                <input type="text" [(ngModel)]="member.data.resident_location" name="resident_location" class="form-control" />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-12">Work Location</label>
                            <div class="col-md-12">
                                <input type="text" [(ngModel)]="member.data.work_location" name="work_location" class="form-control" />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-12">Salary Band</label>
                            <div class="col-md-12">
                                <input type="text" [(ngModel)]="member.data.salary_band" name="salary_band" class="form-control" />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-12">UID No.</label>
                            <div class="col-md-12">
                                <input type="text" [(ngModel)]="member.data.uid_no" name="uid_no" class="form-control" />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-12">File No.</label>
                            <div class="col-md-12">
                                <input type="text" [(ngModel)]="member.data.file_no" name="file_no" class="form-control" />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-12">Membership</label>
                            <div class="col-md-12">
                                <select class="form-control" [(ngModel)]="member.data.member_status" name="member_status">
                                    <option>ACTIVE</option>
                                    <option>NON-ACTIVE</option>
                                </select>
                                
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-12">Application status</label>
                            <div class="col-md-12">
                                <select class="form-control" [(ngModel)]="member.data.application_status" name="application_status">
                                    <option value="1">Received</option>
                                    <option value="2">Not received</option>
                                </select>
                                
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-12">Payment status</label>
                            <div class="col-md-12">
                                <select class="form-control small-input" [(ngModel)]="member.data.payment_status" name="payment_status">
                                    <option value="1">Received</option>
                                    <option value="2">Not received</option>
                                </select>

                                <input *ngIf="member.data.payment_status == 1" class="form-control small-input amount-input" placeholder="Enter amount" type="text" [(ngModel)]="member.data.paid_amount" name="paid_amount"/>
                                
                            </div>
                        </div>
                    </div>

                    

                    



                    
                    
                </div>
    
                
                
    
            </form>

            <div class="row">
                <div class="col-12">
                    <hr/>

                <button *ngIf="!loading" form="formNew" class="btn btn-primary">Save</button>
                <span class="spinner" *ngIf="loading">
                    <i class="fa-solid fa-spinner fa-spin"></i>
                </span>
                &nbsp;
                <ng-container *ngIf="id != 0">
                    <button class="btn btn-outline-secondary" routerLink="/dashboard/members/{{id}}">Cancel</button>    
                </ng-container>
                </div>
            </div>

            

        </div>
    </div>
</div>