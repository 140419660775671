import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  //public REST_API_SERVER = "http://localhost/sttchurch-api/frontend/web/?r="; //development
  public REST_API_SERVER = "https://stthomas-church.metanoa.ai/api/frontend/web/?r="; //production

  constructor(private httpClient: HttpClient) { }

  public login(data) {
    return this.httpClient.post(this.REST_API_SERVER + "account/login", data);
  }

  public headBar() {
    return this.httpClient.get(this.REST_API_SERVER + "account/headbar", {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getMembers(search, searchField, offset, limit, renewalFilter, application_status, payment_status) {
    return this.httpClient.get(this.REST_API_SERVER + "members/list&search="+search+"&searchField="+searchField+"&offset="+offset+"&limit="+limit+"&renewal="+renewalFilter+"&application_status="+application_status+"&payment_status="+payment_status, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getMember(id) {
    return this.httpClient.get(this.REST_API_SERVER + "members/single&id="+id, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postNewMember(data) {
    return this.httpClient.post(this.REST_API_SERVER + "members/add", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postUpdateMember(data) {
    return this.httpClient.post(this.REST_API_SERVER + "members/update", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postDeleteMember(data) {
    return this.httpClient.post(this.REST_API_SERVER + "members/delete", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postEditMember(data) {
    return this.httpClient.post(this.REST_API_SERVER + "members/edit", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getUsers() {
    return this.httpClient.get(this.REST_API_SERVER + "account/users", {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postUser(data) {
    return this.httpClient.post(this.REST_API_SERVER + "account/new_user", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postEditUser(data) {
    return this.httpClient.post(this.REST_API_SERVER + "account/edit_user", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postDeleteUser(data) {
    return this.httpClient.post(this.REST_API_SERVER + "account/delete_user", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postMemberFile(data) {
    return this.httpClient.post(this.REST_API_SERVER + "members/upload_file", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getMemberFiles(id) {
    return this.httpClient.get(this.REST_API_SERVER + "members/files&id="+id, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getMemberFile(key) {
    return this.httpClient.get(this.REST_API_SERVER + "members/get_file_url&key="+key, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getDeleteFile(id) {
    return this.httpClient.get(this.REST_API_SERVER + "members/delete_file&id="+id, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

}
