<div class="page">
    <div class="container-fluid">
        <div class="row page-header">
            <div class="col-md-12">
                <h3>Members ({{data.count}})</h3>
            </div>
        </div>

        <div class="page-body">
            <div class="row" style="margin-bottom: 30px;">
                <div class="col-md-2 position-relative">
                    <small>Search by</small>
                    <select class="form-control" (change)="setSearchField($event.target.value)">
                        <option>Name</option>
                        <option>Phone</option>
                        <!--<option>Staff ID</option>-->
                        <option>Passport No.</option>
                        <!--<option>Card No.</option>-->
                        <option>Cardex No.</option>
                        <option>Emirates ID</option>
                        <option>Principal ID</option>
                    </select>
                    
                </div>
                <div class="col-md-3 position-relative">
                    <form #fsearch="ngForm" (ngSubmit)="searchMember(fsearch.value)" id="formSearch" ngNativeValidate>
                        <small>Search query</small>
                        <input type="text" class="form-control" placeholder="Search here" [(ngModel)]="search" name="search"/>
                        <span *ngIf="search != ''" class="btn-clear-search" (click)="clearSearch()"> &times; </span>
                    </form>
                </div>
                <div class="col-md-5">
                    <small>&nbsp;</small>
                    <span class="spinner" *ngIf="loading">
                        <i class="fa-solid fa-spinner fa-spin"></i>
                      </span>
                </div>
                <div class="col-md-2 text-right">
                    <button routerLink="/dashboard/add" class="btn btn-outline-dark" (click)="focusNameInput()">Add new</button>
                </div>
            </div>

            <table class="table table-bordered">
                <thead>
                    <td>Name</td>
                    <td>Phone</td>
                    <!--<td>Passport No.</td>-->
                    <td>Cardex No.</td>
                    <!--<td>Emirates ID</td>-->
                    <td>Policy Date</td>
                    <td>
                        <span class="dropdown-toggle" data-toggle="dropdown">Renewal/Non-renewal</span>
                        <div class="dropdown-menu">
                            <a class="dropdown-item" href="javascript:void(0);" (click)="filterByRenewal(3)">View All</a>
                            <a class="dropdown-item" href="javascript:void(0);" (click)="filterByRenewal(1)">Renewal</a>
                            <a class="dropdown-item" href="javascript:void(0);" (click)="filterByRenewal(2)">Non-Renewal</a>
                            <a class="dropdown-item" href="javascript:void(0);" (click)="filterByRenewal(0)">Not specified</a>
                        </div>
                        
                    </td>

                    <td>
                        <span class="dropdown-toggle" data-toggle="dropdown">Application status</span>
                        <div class="dropdown-menu">
                            <a class="dropdown-item" href="javascript:void(0);" (click)="filterByApplicationStatus(0)">View All</a>
                            <a class="dropdown-item" href="javascript:void(0);" (click)="filterByApplicationStatus(1)">Received</a>
                            <a class="dropdown-item" href="javascript:void(0);" (click)="filterByApplicationStatus(2)">Not received </a>
                            
                        </div>
                        
                    </td>

                    <td>
                        <span class="dropdown-toggle" data-toggle="dropdown">Payment status</span>
                        <div class="dropdown-menu">
                            <a class="dropdown-item" href="javascript:void(0);" (click)="filterByPaymentStatus(0)">View All</a>
                            <a class="dropdown-item" href="javascript:void(0);" (click)="filterByPaymentStatus(1)">Received</a>
                            <a class="dropdown-item" href="javascript:void(0);" (click)="filterByPaymentStatus(2)">Not received</a>
                            
                        </div>
                        
                    </td>
                    
                    
                </thead>
                <tbody>
                    <tr *ngFor="let item of data.list">
                        <td>
                            <a href="#" routerLink="/dashboard/members/{{item.id}}">
                            {{item.name}}
                            </a>
                        </td>
                        <td>{{item.phone}}</td>
                        <!--<td>{{item.passport_no}}</td>-->
                        <td>{{item.cardex_no}}</td>
                        <!--<td>{{item.emirates_id}}</td>-->
                        <td>{{item.policy_date | date:'dd-MM-yyyy'}}</td>
                        <td>
                            <ng-container *ngIf="item.renewal == 1">Renewal</ng-container>
                            <ng-container *ngIf="item.renewal == 2">Non-Renewal</ng-container>
                            <ng-container *ngIf="item.renewal == 0"> Not specified </ng-container>
                        </td>
                        <td>
                            <ng-container *ngIf="item.application_status == 1">Received</ng-container>
                            <ng-container *ngIf="item.application_status == 2">Not received</ng-container>
                            <ng-container *ngIf="item.application_status == 0"> - </ng-container>
                        </td>
                        <td>
                            <ng-container *ngIf="item.payment_status == 1">Received</ng-container>
                            <ng-container *ngIf="item.payment_status == 2">Not received</ng-container>
                            <ng-container *ngIf="item.payment_status == 0"> - </ng-container>
                        </td>
                        
                    </tr>
                </tbody>
            </table>

            <div class="row">
                <div class="col-md-4">
                    <ng-container *ngIf="offset > 0">
                        <img src="assets/img/icon-arrow-left-active.svg" class="btn-nav" (click)="navPage('prev')"/>
                    </ng-container>
                    
                </div>
                <div class="col-md-4 text-center">
                    {{data.currentPage}}/{{data.totalPages}}
                </div>
                <div class="col-md-4 text-right">
                    <ng-container *ngIf="data.currentPage < data.totalPages">
                        <img src="assets/img/icon-arrow-right-active.svg" class="btn-nav" (click)="navPage('next')"/>
                    </ng-container>
                </div>
            </div>

        </div>
    </div>
</div>


