import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.scss']
})
export class MemberComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }

  member:any  = {
    data:{}
  };
  id:any = 0;
  loading:any = false;
  uploading:any = false;
  uploadFile:any;
  uploadSize:any = "";
  files:any = [];
  

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.loadMember();
    this.loadFiles();
  }

  loadMember(){
    this.dataService.getMember(this.id).subscribe( (data: any )=> {
      this.member = data;
    } );
  }

  loadFiles(){
    this.dataService.getMemberFiles(this.id).subscribe( (data: any )=> {
      
      if(data.status == 0){
        this.files = data.files;
      }
      
    } );
  }

  deleteMember(){
    let data = {id:this.id};
    var cnf = confirm("Do you really want to delete this member from the database?");

    if(cnf){
      this.dataService.postDeleteMember(data).subscribe( (res:any) => {
        this.loading = false;
  
        if(res.status == 0) {
          alert(res.msg);
          this.router.navigate(['/dashboard/members']);
  
        } else {
          alert(res.msg);
        }
  
      });
    }

    

  }

  submitMember(data){
    data.id = this.id;
    this.loading = true;
    this.dataService.postEditMember(data).subscribe( (res:any) => {
      this.loading = false;

      if(res.status == 0) {
        //@ts-ignore
        $("#modalEdit").modal("hide");
        
        //this.loadData();
        

      } else {
        alert(res.msg);
      }

    });
  }

  onFileSelect(e){
    this.uploadFile = e.target.files[0];

    let uploadSize = "";
    if(this.uploadFile.size > 1000 && this.uploadFile.size < 1000000 ){
      uploadSize = (this.uploadFile.size / 1000).toFixed(1) + " KB";

    } else if(this.uploadFile.size >= 1000000){
      uploadSize = (this.uploadFile.size / 1000000).toFixed(1) + " MB";
    }
     else {
      uploadSize = this.uploadFile.size + " Bytes";
    }

    this.uploadSize = uploadSize;

  }

  submitFile(){
    

    if(typeof this.uploadFile !== undefined && this.uploadFile != null){
      this.uploading = true;
      const formData = new FormData();
      formData.append('file', this.uploadFile, this.uploadFile.name);
      formData.append("member_id", this.id);

      if(this.uploadFile.size < 5242880){
        this.dataService.postMemberFile(formData).subscribe( (res:any = {status:0, msg:""}) => {
          this.uploading = false;
          if(res.status == 0) {
            
            this.loadFiles();
            //this.uploadFile =  null;
            
            var input = document.getElementById('fileInput');
            input.setAttribute("type", "text");
            input.setAttribute("type", "file");
            this.uploadSize = "";
            
          } else {
            alert(res.msg);
          }
        
        });
      } else {
        this.uploading = false;
        alert("You can't upload files greater than 5MB in size");
      }

      

    }

    

  }

  openFile(file){
    file.openingFile = true;
    this.dataService.getMemberFile(file.file_url).subscribe( (data: any )=> {
      file.openingFile = false;

      if(data.status == 0){
        window.open(data.url);
        console.log("data", data);
      }
      
    } );
  }

  deleteFile(file){
    var cnf = confirm("Do you really want to delete this file?");

    if(cnf){
      file.deletingFile = true;
      this.dataService.getDeleteFile(file.id).subscribe( (data: any )=> {
        //file.deletingFile = false;

        if(data.status == 0){
          this.loadFiles();
        } else {
          alert(data.msg);
        }
        
      } );
    }
    
  }

}
