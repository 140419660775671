import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule }from'@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { UsersComponent } from './dashboard/users/users.component';
import { MembersComponent } from './dashboard/members/members.component';
import { MemberComponent } from './dashboard/member/member.component';
import { ManageMemberComponent } from './dashboard/manage-member/manage-member.component';

const routes: Routes = [
  {
    path: '', component:LoginComponent
  },
  {
    path: 'login', component:LoginComponent
  },
  {
    path: 'dashboard', component:DashboardComponent,
    children: [
      {
        path: 'users', component:UsersComponent
      },  
      {
        path: 'members', component:MembersComponent
      },
      {
        path: 'members/:id', component:MemberComponent
      },
      {
        path: 'add', component:ManageMemberComponent
      },
      {
        path: 'edit/:id', component:ManageMemberComponent
      },
    ]
  }
];

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    UsersComponent,
    MembersComponent,
    MemberComponent,
    ManageMemberComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
    }),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
