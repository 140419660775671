<div class="dashboardPage">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <a class="navbar-brand" href="#">
            <img src="assets/img/app-logo.png" style="margin-top:10px;" />
        </a>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
    
            <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
              <!--
              <a href="javascript:void(0);" (click)="goBack()"> <img src="assets/img/chevron-left-inactive-2.png" style="margin-left: 20px;"> </a>
              -->
              
              <!-- <li class="hello-there">
                 <strong>Hello,</strong>
                <span>there!</span>
              </li> -->
            </ul>
    
          
          
          
          
       
            
          <ul>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <div class="user-info-box">
                    <div class="user-info-pic" >
                      <img *ngIf="data.hasImg==1; else noImg" src="{{data.img_url}}" style="width:100%;" />
                      <ng-template #noImg>
                        <img src="assets/img/user-placeholder.png" style="width:100%;" />
                      </ng-template>
                    </div>
                    <div class="user-info-right">
                      <h5 *ngIf="data.name">{{data.name}}</h5>
                      <p *ngIf="data.role"> {{data.role}} </p>
                    </div>
                  </div>
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown" data-html2canvas-ignore>
                  <!--
                  <a class="dropdown-item" href="#" routerLink="/dashboard/profile">
                    <img src="assets/img/patient-icon.png" /> <span>Profile</span>
                  </a>
    
                  <a class="dropdown-item" id="capture" href="javascript:void(0);">
                    <img src="assets/img/icon-issue.png" /> <span>Report an Issue</span>
                  </a>
                  
                  
                  <div class="dropdown-divider"></div> -->
                  <a class="dropdown-item" href="javascript:void(0);" (click)="signOut()" >
                    <img src="assets/img/logout.png" /> <span>Sign out</span>
                  </a>
                </div>
              </li>
          </ul>
          
        </div>
    
    
    
    </nav>

    <div class="wrapper">
        <div id="sidebar">
            <ul class="primary-menu-ul">
                <li class="" [routerLinkActive]="'active'" >
                    <a href="" routerLink="/dashboard/members">
                      <img src="assets/img/dashboard-icon.png"/>
                      <span>Members</span>
                    </a>
                </li>
                
                <li class="" [routerLinkActive]="'active'" >
                  <a href="" routerLink="/dashboard/users">
                    <img src="assets/img/dashboard-icon.png"/>
                    <span>Users</span>
                  </a>
                </li>
                
            </ul>
        </div>

        <div id="dashboard-content" style="min-height: 670px;">
          <router-outlet></router-outlet>
        </div>

    </div>

</div>