import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(private dataService: DataService, private router:Router) { }
  data:any = {name:"name", role:"role"};
  key:any = null;
  role_id:any = 0;

  ngOnInit(): void {
    this.key = localStorage.getItem('key');
    if(this.key == null) {
      this.router.navigate(['/login']);
    }
    this.role_id = localStorage.getItem('role_id');
    this.headBar();
  }

  headBar(){
    this.dataService.headBar().subscribe( (data: any )=> {
      this.data = data;
      
      
      localStorage.setItem('user_id', data.user_id);
      
    } );
  }

  signOut(){
    localStorage.removeItem("key");
    this.router.navigate(['/login']);
  }

}
