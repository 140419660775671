import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';


@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss']
})
export class MembersComponent implements OnInit {

  constructor(private dataService: DataService) { }

  data:any = {status:0, list:[], count:0, search:"", searchField:"", currentPage:1, totalPages:1};
  search:any = "";
  searchField:any = "";
  offset:any = 0;
  loading:any = false;
  limit:any = 30;
  renewalFilter:any = 3;
  applicationStatusFilter:any = 0;
  paymentStatusFilter:any = 0;

  ngOnInit(): void {
    this.loadData();
  }

  loadData(){
    this.loading = true;
    this.dataService.getMembers(this.search, this.searchField, this.offset, this.limit, this.renewalFilter, this.applicationStatusFilter, this.paymentStatusFilter).subscribe( (data: any )=> {
      this.data = data;
      this.loading = false;
    } );
  }

  clearSearch(){
    this.search = "";
    this.loadData();
  }

  searchMember(data){
    this.search = data.search;
    this.offset = 0;
    this.loadData();
  }

  setSearchField(val){
    this.searchField = val;
    if(this.search != null){
      this.loadData();
    }
  }

  submitMember(data){
    
    this.loading = true;
    this.dataService.postNewMember(data).subscribe( (res:any) => {
      this.loading = false;
      
      if(res.status == 0) {
        //@ts-ignore
        $("#modalAdd").modal("hide");
        this.search = data.name;
        this.loadData();
        

        //@ts-ignore
        document.getElementById("formNew").reset();

      } else {
        alert(res.msg);
      }

    });
  }

  focusNameInput(){
    //
  }

  navPage(dir){
    
    if(dir == "next"){
      this.offset += this.limit;
    } else {

      if(this.offset - this.limit < 0){
        this.offset = 0;
      } else {
        this.offset -= this.limit;
      }

    }

    this.loadData();

  }

  filterByRenewal(val){
    this.renewalFilter = val;
    this.loadData();
  }

  filterByApplicationStatus(val){
    this.applicationStatusFilter = val;
    this.loadData();
  }

  filterByPaymentStatus(val){
    this.paymentStatusFilter = val;
    this.loadData();
  }

}
