<div class="page">
    <div class="container-fluid">
        

        <div class="page-body">
            <div class="row">
                <div class="col-8">
                    <h3>{{member.data.name}}</h3>
                    
                </div>
                <div class="col-4 text-right">
                    <i class="fa-solid fa-user-pen btn-member btn-edit" routerLink="/dashboard/edit/{{member.data.id}}"></i> &nbsp; &nbsp;
                    <i class="fa-solid fa-trash-can btn-member btn-delete" (click)="deleteMember()"></i> 
                    
                </div>
            </div>
            <hr/>

            <!-- member data-->

            <div class="row data-row">
                <div class="col-3">
                    <label>Name</label>
                    <p class="member-data">{{member.data.name}}</p>
                </div>

                <div class="col-3">
                    <label>DOB</label>
                    <p class="member-data">{{member.data.dob | date:'dd-MM-yyyy'}}</p>
                </div>

                <div class="col-3">
                    <label>age</label>
                    <p class="member-data">{{member.age}}</p>
                </div>

                <div class="col-3">
                    <label>gender</label>
                    <p class="member-data">{{member.data.gender}}</p>
                </div>

                <div class="col-3">
                    <label>staff id</label>
                    <p class="member-data">{{member.data.staff_id}}</p>
                </div>

                <div class="col-3">
                    <label>passport number</label>
                    <p class="member-data">{{member.data.passport_no}}</p>
                </div>

                <div class="col-3">
                    <label>Type</label>
                    <p class="member-data">
                        <ng-container *ngIf="member.data.renewal == 1">Renewal</ng-container>
                        <ng-container *ngIf="member.data.renewal == 2">Non-Renewal</ng-container>
                        <ng-container *ngIf="member.data.renewal == 3">New</ng-container>
                        <ng-container *ngIf="member.data.renewal == 0"> Not specified </ng-container>
                    </p>
                </div>

                <div class="col-3">
                    <label>policy date</label>
                    <p class="member-data">{{member.data.policy_date | date:'dd-MM-yyyy'}}</p>
                </div>

                <div class="col-3">
                    <label>policy inception date</label>
                    <p class="member-data">{{member.data.policy_inception | date:'dd-MM-yyyy'}}</p>
                </div>

                <div class="col-3">
                    <label>policy expiry date</label>
                    <p class="member-data">{{member.data.policy_expiry | date:'dd-MM-yyyy'}}</p>
                </div>

                <div class="col-3">
                    <label>phone</label>
                    <p class="member-data">{{member.data.phone}}</p>
                </div>
                <div class="col-3">
                    <label>Card No.</label>
                    <p class="member-data">{{member.data.card_number}}</p>
                </div>
                <div class="col-3">
                    <label>member id</label>
                    <p class="member-data">{{member.data.member_id}}</p>
                </div>
                <div class="col-3">
                    <label>principal id</label>
                    <p class="member-data">{{member.data.principal_id}}</p>
                </div>
                <div class="col-3">
                    <label>principal name</label>
                    <p class="member-data">{{member.data.principal_name}}</p>
                </div>
                <div class="col-3">
                    <label>dependency</label>
                    <p class="member-data">{{member.data.dependency}}</p>
                </div>
                <div class="col-3">
                    <label>category</label>
                    <p class="member-data">{{member.data.category}}</p>
                </div>
                <div class="col-3">
                    <label>cardex no.</label>
                    <p class="member-data">{{member.data.cardex_no}}</p>
                </div>
                <div class="col-3">
                    <label>network</label>
                    <p class="member-data">{{member.data.network}}</p>
                </div>
                <div class="col-3">
                    <label>inception date</label>
                    <p class="member-data">{{member.data.inception_date}}</p>
                </div>
                <div class="col-3">
                    <label>expiry date</label>
                    <p class="member-data">{{member.data.expiry_date}}</p>
                </div>
                <div class="col-3">
                    <label>endo date</label>
                    <p class="member-data">{{member.data.endo_date}}</p>
                </div>
                <div class="col-3">
                    <label>emirates id</label>
                    <p class="member-data">{{member.data.emirates_id}}</p>
                </div>
                <div class="col-3">
                    <label>nationality</label>
                    <p class="member-data">{{member.data.nationality}}</p>
                </div>
                <div class="col-3">
                    <label>marital status</label>
                    <p class="member-data">{{member.data.marital_status}}</p>
                </div>
                <div class="col-3">
                    <label>visa emirate</label>
                    <p class="member-data">{{member.data.visa_emirate}}</p>
                </div>
                <div class="col-3">
                    <label>email</label>
                    <p class="member-data">{{member.data.email}}</p>
                </div>
                <div class="col-3">
                    <label>resident location</label>
                    <p class="member-data">{{member.data.resident_location}}</p>
                </div>
                <div class="col-3">
                    <label>work location</label>
                    <p class="member-data">{{member.data.work_location}}</p>
                </div>
                <div class="col-3">
                    <label>salary band</label>
                    <p class="member-data">{{member.data.salary_band}}</p>
                </div>
                <div class="col-3">
                    <label>uid no.</label>
                    <p class="member-data">{{member.data.uid_no}}</p>
                </div>
                <div class="col-3">
                    <label>file no.</label>
                    <p class="member-data">{{member.data.file_no}}</p>
                </div>

                <div class="col-3">
                    <label>Membership</label>
                    <p class="member-data">{{member.data.member_status}}</p>
                </div>

                <div class="col-3">
                    <label>Application status</label>
                    <p class="member-data">
                        <ng-container *ngIf="member.data.application_status == 1">Received</ng-container>
                        <ng-container *ngIf="member.data.application_status == 2">Not received</ng-container>
                    </p>
                </div>

                <div class="col-3">
                    <label>Payment status</label>
                    <p class="member-data">
                        <ng-container *ngIf="member.data.payment_status == 1">Received</ng-container>
                        <ng-container *ngIf="member.data.payment_status == 2">Not received</ng-container>

                        <ng-container *ngIf="member.data.payment_status == 1">
                            <br/>
                            
                            <small>Amount received: {{member.data.paid_amount}}</small>
                            
                        </ng-container>

                    </p>
                </div>

                
                
                


            </div>

            <hr/>

            <div class="row data-row">
                <div class="col-8">
                    <label>Uploaded files</label>
                    <!-- list files -->
                    <div *ngIf="files.length > 0">
                        <p *ngFor="let file of files">
                            <span class="cursor-pointer" (click)="openFile(file)">
                                <i *ngIf="!file.openingFile" class="fa-regular fa-file"></i>
                                <i *ngIf="file.openingFile" class="fa-solid fa-spinner fa-spin"></i>
                                {{file.file_url}}
                            </span>
                            &nbsp; &nbsp;&nbsp;&nbsp;
                            <i *ngIf="!file.deletingFile" class="fa-solid fa-trash-can cursor-pointer" (click)="deleteFile(file)"></i> 
                            <i *ngIf="file.deletingFile" class="fa-solid fa-spinner fa-spin"></i>
                        </p>
                    </div>

                    <p class="text-muted" *ngIf="files.length == 0">
                        No files to show
                    </p>
                    <p style="margin-top: 30px;"></p>
                    <!-- upload form-->
                    <form #ffile="ngForm" (ngSubmit)="submitFile()" id="formFile" ngNativeValidate>
                        <div class="row" style="margin-bottom: 20px">
                    
                            <div class="col-md-6">
                                <label class="control-label col-md-12">Upload file</label>
                                <div class="col-md-12">
                                    <input id="fileInput" type="file" class="form-control" (change)="onFileSelect($event)" required/>
                                    <p class="text-center size-warning" *ngIf="uploadSize != ''">
                                        File size is {{uploadSize}}. 
                                        <ng-container *ngIf="uploadFile.size >= 5242880">
                                          You can't upload files greater than 5MB
                                        </ng-container>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="row" >
                
                        <div class="col-md-6" style="margin-left: 20px">
                            <button *ngIf="!uploading" class="btn btn-primary" form="formFile">Upload</button>
                            <span class="spinner" *ngIf="uploading">
                                <i class="fa-solid fa-spinner fa-spin"></i>
                              </span>
                        </div>
                    </div>

                </div>
            </div>

    </div>
</div>

<!-- modals -->

<div class="modal fade" id="modalEdit" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Edit member</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form #fnew="ngForm" (ngSubmit)="submitMember(fnew.value)" id="formNew" ngNativeValidate>
            
              
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label col-md-12">Name</label>
                        <div class="col-md-12">
                            <input type="text" id="inputName" [(ngModel)]="member.data.name" name="name" class="form-control" required/>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label col-md-12">Date of Birth</label>
                        <div class="col-md-12">
                            <input type="date" [(ngModel)]="member.data.dob" name="dob" class="form-control" />
                        </div>
                    </div>
                </div>
                
                
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label col-md-12">Gender</label>
                        <div class="col-md-12">
                            <select class="form-control" [(ngModel)]="member.data.gender" name="gender" required>
                                <option>Male</option>
                                <option>Female</option>
                                
                            </select>
                            
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label col-md-12">Staff ID</label>
                        <div class="col-md-12">
                            <input type="text" [(ngModel)]="member.data.staff_id" name="staff_id" class="form-control" />
                        </div>
                    </div>
                </div>
                
                
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label col-md-12">Passport No.</label>
                        <div class="col-md-12">
                            <input type="text" [(ngModel)]="member.data.passport_no" name="passport_no" class="form-control"/>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label col-md-12">Policy No.</label>
                        <div class="col-md-12">
                            <input type="text" [(ngModel)]="member.data.policy_no" name="policy_no" class="form-control" />
                        </div>
                    </div>
                </div>
                
                
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label col-md-12">Plan</label>
                        <div class="col-md-12">
                            <input type="text" [(ngModel)]="member.data.plan" name="plan" class="form-control"/>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label col-md-12">Policy Date</label>
                        <div class="col-md-12">
                            <input type="date" [(ngModel)]="member.data.policy_date" name="policy_date" class="form-control" />
                        </div>
                    </div>
                </div>
                
                
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label col-md-12">Policy Inception Date</label>
                        <div class="col-md-12">
                            <input type="date" [(ngModel)]="member.data.policy_inception" name="policy_inception" class="form-control"/>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label col-md-12">Policy Expiry Date</label>
                        <div class="col-md-12">
                            <input type="date" [(ngModel)]="member.data.policy_expiry" name="policy_expiry" class="form-control" />
                        </div>
                    </div>
                </div>
                
                
            </div>
            

          </form>
        </div>
        <div class="modal-footer">
            
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button *ngIf="!loading" form="formNew" class="btn btn-primary">Save</button>
          <span class="spinner" *ngIf="loading">
            <i class="fa-solid fa-spinner fa-spin"></i>
          </span>
        </div>
      </div>
    </div>
</div>